// React Basic and Bootstrap
import React, { useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
// Import images
import "../assets/styles/index.scss"
import SEO from "../components/SEO/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

const Welcome = ({ data: { image } }) => {

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "Lead")
      }
      if (window.gtag != null) {
        window.gtag("event", "conversion", { "send_to": "AW-670520049/TxS2CIWN9b8BEPGl3b8C" })
      }
    }
  })

  return <>
    <SEO
      title={"Bienvenue sur Bizyness"} noindex={true}
    />
    <Container className="mb-5">
      <Row className="justify-content-center">
        <Col lg="7" md="7" className="text-center">
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            className="img-fluid"
            style={{ maxHeight: "400px", maxWidth: "459px", margin: "0 auto" }}
            alt="Creation de compte Bizyness"/>
          <div className="title-heading mt-0 mt-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h1 className="heading mb-3">Bienvenue à bord ! </h1>
            <p className="para-desc text-muted">Vous allez recevoir d'ici quelques secondes un email avec vos
              identifiants afin de vous connecter à votre compte Bizyness.</p>
            <div>
              <a href="https://app.bizyness.fr/#/login" className="btn btn-primary mt-2 mr-2">Se connecter</a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </>
}

export default Welcome

Welcome.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object.isRequired
  }).isRequired
}

export const pageQuery = graphql`query Welcome {
  image: file(relativePath: {eq: "marketing.png"}) {
    childImageSharp {
      gatsbyImageData(width: 459, quality: 90, placeholder: NONE, layout: CONSTRAINED)
    }
  }
}
`